<template>
    <div class="container" style="padding-top:50px;"> 
      <div class="p-3">
        <div class="d-flex justify-content-between align-items-center"> 
          <div class="head-order ps-2">
            <h4>Random Order</h4>
            <p> 
              A set of random requests will appear for you to choose from it.
            </p>
          </div> 
          <span @click="updateItem" class="reapit"  style="cursor:pointer;display:inline-block;">
            <svg xmlns="http://www.w3.org/2000/svg" height="48" fill="#2C3E50" width="48"><path d="m14 44-8-8 8-8 2.1 2.2-4.3 4.3H35v-8h3v11H11.8l4.3 4.3Zm-4-22.5v-11h26.2l-4.3-4.3L34 4l8 8-8 8-2.1-2.2 4.3-4.3H13v8Z"/></svg>
          </span>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" v-for="random in randoms" :key="random.id" >
            <CardTrend  :id="random.id" :name="random.name" :price="random.price" :imga="random.imga" :desc="random.desc" :count="random.count" />
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
    import CardTrend from "../parts/CardTrend.vue"
  export default {
    name: 'Random-Page',
    data(){
      return{
        randoms : []
      } 
    },
    components:{
      CardTrend
    },
    methods:{
        updateItem:function(){
          this.$store.dispatch("resetrandom")
          this.randoms = []
          this.$store.getters.randFood  
          this.randoms = this.$store.state.randomvalue 
        }
    },
    mounted(){
        this.$store.dispatch("resetrandom")
        this.randoms = []
        this.$store.getters.randFood  
        this.randoms = this.$store.state.randomvalue
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .head-order{
      padding: 20px 0px ;
    }
    .head-order h4{
      font-weight:300px;
      color:#2C3E50;
    }
    .head-order p{
      opacity:0.8;
      color:#2C3E50;
      font-size:14px;
    }
    .reapit svg{
      transition:all 0.2s ease;
    }
    .reapit:hover svg{
      fill:#FFB200 !important;
    }
  </style>
  