<template>
    <tr>
      <td>{{name}}</td>
      <td>{{price}}</td>
      <td>{{count}}</td> 
      <td>{{totalCount}}</td> 
    </tr>
</template>
  
  <script>
  export default {
    name: 'List-Bill',
    data(){
      return{
      }
    },
    props:['price','name','count'],
    computed:{
      totalCount:function(){
        return this.count * this.price;
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    
  </style>
  