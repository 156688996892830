<template>
    <div :class="['mix' ,'card', 'position-relative', 'mb-3', cate ]" style="max-width: 540px;">
        <div class="row g-0 position-relative" >
          <div class="col-md-4">
              <img :src="imga"  class="img-fluid img-menu rounded-start" alt="">
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{{name}}</h5>
              <p class="card-text">{{desc}}</p>
              <p class="card-text"><small class="text-muted">Preparation time {{time}}</small></p>
            </div>
          </div>
        </div>
        <span class="price-menu position-absolute" >
          <label>{{price}}</label>
          <svg xmlns="http://www.w3.org/2000/svg" height="48" fill="#2C3E50" width="48"><path d="M27.95 43.15q-.9.9-2.175.9t-2.175-.9L4.85 24.4q-.5-.5-.675-1.05Q4 22.8 4 22.2V7q0-1.3.85-2.15Q5.7 4 7 4h15.2q.6 0 1.2.175t1.1.675L43.15 23.5q.95.95.95 2.225 0 1.275-.95 2.225ZM25.9 41.1l15.2-15.2L22.2 7H7v15.2ZM12.25 14.8q1.05 0 1.825-.775.775-.775.775-1.825 0-1.05-.775-1.825Q13.3 9.6 12.25 9.6q-1.05 0-1.825.775-.775.775-.775 1.825 0 1.05.775 1.825.775.775 1.825.775ZM7 7Z"/></svg>
        </span>
        <span @click="getId(id)" class="add-menu position-absolute">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M6 31.5v-3h15v3Zm0-8.25v-3h23.5v3ZM6 15v-3h23.5v3Zm26.5 25v-8.5H24v-3h8.5V20h3v8.5H44v3h-8.5V40Z"/></svg>
        </span>
    </div>
</template>
  
  <script>
  export default {
    name: 'Card-Menu',
    data(){
      return{
      }
    },
    components:{
    },
    methods:{
      getId:function(id){
        this.$store.dispatch("getid" , id);
        this.$store.getters.showPointCatrs
      }
    },
    props:['id','imga','name','desc','time' ,'cate', 'price']
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
     .card{
      overflow:hidden;
      border:none !important;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    .card-title {
      color:#2C3E50  !important;
    }
    .card-text{
      opacity:0.8;
    }
    .price-menu{
      color:#2C3E50;
      font-weight:700;
      font-size:20px;
      opacity:0.5;
      bottom:0px;
      right:0px;
      transition:all 0.5s ease;
    }
    .add-menu{
      transition:all 0.3s ease;
      top:0px;
      right:-60px
    }
    .price-menu svg{
      transform:scale(0.8)
    }
    .add-menu svg{
      transform:scale(0.8)
    }
    .card:hover .price-menu{
      opacity:0.9;
      color:#FFB200;
    }
    .img-menu{
      height: 100% !important;
      object-fit: cover;
    }
    .card:hover .add-menu{
      right:0px;
    }
  </style>
  