<template>
  <div class="app">
    <div class="container-app">
      <NavBar></NavBar>
      <router-view v-slot="{Component}">
        <tranistion name="route" mode="out-in">
          <component :is="Component"></component>
        </tranistion>
      </router-view>
    </div>
    <span class="box-black"></span>
  </div>
  <Slider />
</template>

<script>
import NavBar from "./components/parts/NavBar.vue"
import Slider from "./components/parts/Slider.vue"

export default {
  name: 'App',
  data(){
    return{
 
    }
  },
  methods:{
    
  },
  components: {
    NavBar,
    Slider
  }
}
</script>

<style>
  .app{
    position: relative;
    width:100%;
    min-height:100vh;
    background-image: url("./assets/shawarma.jpg");
    background-attachment: fixed;
    background-size: cover;
  }
  ::-webkit-scrollbar{width:  1px;}
  ::-webkit-scrollbar-track{background-color: #2C3E50;}
  ::-webkit-scrollbar-thumb{background-color: #FFB200;border-radius: 50px;}
  .box-black{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: linear-gradient(90deg, rgba(255,255,255,0.5242471988795518) 0%, rgba(255,255,255,1) 50%);
    opacity: 0.9;
    z-index: 10;
  }
  .container-app{
    position: relative;
    z-index: 100;
  }
  .route-enter-from{opacity:0;}
  .route-enter-to{opacity:1;}
  .route-enter-active{transition:all 3s ease;}
  .route-leave-from{opacity:1;}
  .route-leave-to{opacity:0;}
  .route-leave-active{transition:all 3s ease;}
</style>
