import { createApp } from 'vue'
import App from './App.vue'
import router from "./routes.js"
import stoe from "./vuex.js"
import "bootstrap/dist/css/bootstrap.min.css"


const app = createApp(App)

app.use(router)
app.use(stoe)
router.isReady().then(()=>{
   app.mount('#app') 
})

import "bootstrap/dist/js/bootstrap.min.js"
