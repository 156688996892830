<template>
   <nav>
      <div class="container">
        <div class="navbar-container d-flex justify-content-between align-items-center">
          <!-- this is button show and hide nav in screen sm 549px -->
          <div @click="changeisshwo" class="bar-nav w-100 text-end d-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#FFB200" height="48" width="48"><path d="M18.45 36v-3.05H42V36Zm0-10.5v-3H42v3ZM6 15v-3h36v3Z"/></svg>
          </div>
          <!-- navbar -->
          <div class="parts-nav partstart" :style="isShow? {display:'block'} : {display:'none'}">
            <LinkNav v-for="item in links" :key="item.title" :link="item.link" :color="item.color" :title="item.title" :icon="item.icon"></LinkNav>
          </div>
          <div class="parts-nav partend" :style="isShow? {display:'block'} : {display:'none'}">
            <div class="part-nav">
              <div class="nav-block d-flex flex-column align-items-center justify-content-between">
                <span class="nav-icon position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFB200" height="48" width="48"><path d="m17.3 45-3.8-6.5-7.55-1.55.85-7.35L2 24l4.8-5.55-.85-7.35 7.55-1.55L17.3 3 24 6.1 30.7 3l3.85 6.55 7.5 1.55-.85 7.35L46 24l-4.8 5.6.85 7.35-7.5 1.55L30.7 45 24 41.9Zm1.35-3.95L24 38.8l5.5 2.25 3.35-5 5.85-1.5-.6-5.95 4.05-4.6-4.05-4.7.6-5.95-5.85-1.4-3.45-5L24 9.2l-5.5-2.25-3.35 5-5.85 1.4.6 5.95L5.85 24l4.05 4.6-.6 6.05 5.85 1.4ZM24 24Zm-2.15 6.65L33.2 19.4l-2.25-2.05-9.1 9-4.75-4.95-2.3 2.25Z"/></svg>
                </span>
                <span class="nav-title">{{this.$store.getters.levelUser}}</span>
              </div>
            </div>
            <div class="part-nav">
              <div data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" class="nav-block d-flex flex-column align-items-center justify-content-between">
                <span class="nav-icon position-relative">
                    <span v-show="this.$store.getters.showPointCatrs"  class="position-absolute top-0 start-100 translate-middle p-2 bg-danger rounded-circle"></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#EB5353" height="48" width="48"><path d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V14.5q0-1.2.9-2.1.9-.9 2.1-.9h5.5q0-3.95 2.65-6.725Q19.8 2 23.75 2q3.95 0 6.85 2.775 2.9 2.775 2.9 6.725H39q1.2 0 2.1.9.9.9.9 2.1V41q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V14.5H9V41Zm15-14.5q3.95 0 6.85-2.9 2.9-2.9 2.9-6.85h-3q0 2.75-2 4.75t-4.75 2q-2.75 0-4.75-2t-2-4.75h-3q0 3.95 2.9 6.85 2.9 2.9 6.85 2.9Zm-6.5-15h13q0-2.75-1.875-4.625T24 5q-2.75 0-4.625 1.875T17.5 11.5ZM9 41V14.5 41Z"/></svg>
                </span>
                <span class="nav-title">Cart</span>
              </div>
            </div>
            <div class="part-nav">
              <div class="nav-block d-flex flex-column align-items-center justify-content-between">
                <span class="nav-icon position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" :fill="colors" height="48" width="48"><path d="M22 25.5q.65 0 1.075-.45.425-.45.425-1.05 0-.65-.425-1.075Q22.65 22.5 22 22.5q-.6 0-1.05.425-.45.425-.45 1.075 0 .6.45 1.05.45.45 1.05.45ZM13 42v-3l14-2.45v-23.3q0-.7-.375-1.225-.375-.525-1.025-.625L13 9.45V6.5l13.3 2.3q1.6.25 2.65 1.525Q30 11.6 30 13.2V39Zm-7 0v-3h4V9.5q0-1.3.875-2.15Q11.75 6.5 13 6.5h22q1.3 0 2.15.85.85.85.85 2.15V39h4v3Zm7-3h22V9.5l-22-.05Z"/></svg>
                </span>
                <span class="nav-title">{{isOpen?'Open' : 'Colse'}} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
   </nav>
</template>
  
  <script>
import LinkNav from "./LinkNav.vue"
  export default {
    name: 'NavBar',
    data(){
      return{
        isShow:false,
        links : [
          {
            title: "Home",
            link: "/",
            color:"#2C3E50",
            icon: "M8 42V21.95l-4.2 3.2-1.8-2.4 6-4.55v-5.7h3v3.4L24 6l22 16.8-1.8 2.35-4.2-3.2V42Zm3-3h11.5v-8h3v8H37V19.65l-13-9.9-13 9.9ZM8 10q0-2.3 1.625-3.9T13.5 4.5q1.05 0 1.775-.75Q16 3 16 2h3q0 2.25-1.6 3.875T13.5 7.5q-1 0-1.75.725T11 10Zm3 29h26-26Z"
          },
          {
            title: "About",
            link: "/about",
            color:"#2C3E50",
            icon: "M7.2 46v-3h33.6v3Zm0-41V2h33.6v3ZM24 25.8q2.5 0 4.2-1.7 1.7-1.7 1.7-4.2t-1.7-4.2Q26.5 14 24 14t-4.2 1.7q-1.7 1.7-1.7 4.2t1.7 4.2q1.7 1.7 4.2 1.7ZM6.6 40q-1.2 0-2.1-.9-.9-.9-.9-2.1V11q0-1.3.9-2.15Q5.4 8 6.6 8h34.8q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm4.4-3q2.55-3.15 6.05-4.725Q20.55 30.7 24 30.7q3.4 0 6.975 1.575Q34.55 33.85 37 37h4.4V11H6.6v26Zm4.7 0h16.7q-1.55-1.5-3.625-2.4-2.075-.9-4.775-.9t-4.725.9q-2.025.9-3.575 2.4ZM24 22.8q-1.2 0-2.025-.85t-.825-2.05q0-1.2.825-2.05Q22.8 17 24 17t2.025.85q.825.85.825 2.05 0 1.2-.825 2.05-.825.85-2.025.85Zm0 1.2Z"
          },
          {
            title: "Menu",
            link: "/menu",
            color:"#2C3E50",
            icon: "m8.25 42-2.1-2.1 21-21q-1.1-2.4-.5-4.975.6-2.575 2.85-4.825 2.25-2.2 5.45-2.825T40.2 7.7q2.1 2.1 1.425 5.25T38.6 18.5q-2.05 2.1-4.575 2.75-2.525.65-4.725-.3l-3.35 3.35 15.6 15.6-2.1 2.1-15.6-15.6Zm6.25-17.55L8.55 18.5Q6 15.95 5.9 12.45q-.1-3.5 2.3-6.2L20.45 18.5Z"
          }
        ],
        isOpen: this.$store.state.statusOpen,
        colors:this.$store.state.statusOpen?'#36AE7C':'#EB5353'
      }
    },
    components:{
      LinkNav,
    },
    methods:{
      changeisshwo:function(){
        this.isShow = !this.isShow;
      }
    },
    mounted(){
      if(document.body.clientWidth > 549 ){
        this.isShow = true
      }else{
        this.isShow = false
      }
    },
    
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  nav{
    background-color: #2C3E50;
    position:absolute;
    z-index: 900;
    width:100%;
    box-shadow: 0px 0px 2px 0px #2C3E50;
  }
  .parts-nav{
    height:50px
  }
  .part-nav{
    display:inline-block;
    width:80px;
    height:100%;
    position:relative;
    overflow: hidden;
  }
  .nav-block{
    position:absolute;
    width:100%;
    height:100px;
    top:12px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .nav-title{
    font-size:18px;
    color:white;
  }
  .nav-block:hover{
    top:-49px;
    background-color: #FFB200;
  }
  .nav-icon{
    transform:scale(0.6);
  }
  .router-link-exact-active .nav-block{
    top:-49px;
    background-color: #FFB200;
  }
  @media only screen and (max-width: 549px){
    .navbar-container{
      flex-direction: column;
    }
    .bar-nav{
      display:inline-block !important;
    }
    .parts-nav.partstart{
      width:100%;
      text-align: start;
    }
    .parts-nav.partend{
      width:100%;
      text-align: end;
    }
  }
  </style>
  