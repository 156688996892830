<template>
    <li class="row card-order">
        <div class="col-10 col-sm-8">
            <div class="card position-relative mb-3" style="max-width: 540px;">
                <div class="row g-0 position-relative" >
                <div class="col-md-4">
                    <img :src="imga"  class="img-fluid img-menu rounded-start" alt="">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                    <h5 class="card-title">{{name}}</h5>
                    <p class="card-text">{{desc}}</p>
                    <p class="card-text"><small class="text-muted">Preparation time {{time}}</small></p>
                    </div>
                </div>
                </div>
                <span class="price-menu position-absolute" >
                    <label>{{price}}</label>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" fill="#2C3E50" width="48"><path d="M27.95 43.15q-.9.9-2.175.9t-2.175-.9L4.85 24.4q-.5-.5-.675-1.05Q4 22.8 4 22.2V7q0-1.3.85-2.15Q5.7 4 7 4h15.2q.6 0 1.2.175t1.1.675L43.15 23.5q.95.95.95 2.225 0 1.275-.95 2.225ZM25.9 41.1l15.2-15.2L22.2 7H7v15.2ZM12.25 14.8q1.05 0 1.825-.775.775-.775.775-1.825 0-1.05-.775-1.825Q13.3 9.6 12.25 9.6q-1.05 0-1.825.775-.775.775-.775 1.825 0 1.05.775 1.825.775.775 1.825.775ZM7 7Z"/></svg>
                </span>
            </div>
        </div>
        <div class="col-2 col-sm-4">
            <div class="d-flex flex-sm-row flex-column gap-2 h-100 justify-content-center align-items-center text-center">
                <div>
                    <div class="btn-order-edit d-flex flex-column ms-auto">
                        <span @click="addCount(id)" class="plus">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M22.5 38V25.5H10v-3h12.5V10h3v12.5H38v3H25.5V38Z"/></svg>
                        </span>
                        <span>{{count}}</span>
                        <span @click="minusCount(id)" class="minus">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M10 25.5v-3h28v3Z"/></svg>
                        </span> 
                    </div>
                </div>
                <div>
                    <button @click="deleteitem(id)" type="button" class="d-flex justify-content-center align-items-center btn-delete-order btn btn-danger  btn-sm">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M14 25.35h20v-3H14ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/></svg>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </li>
</template>
  
  <script>
  export default {
    name: 'Card-Order',
    data(){
      return{
      }
    },
    components:{
    },
    methods:{
        deleteitem : function (id){
            this.$store.dispatch("deleteItem" , id)
            this.$store.state.totalbill = 0
            this.$store.getters.totalSell
        },
        addCount : function(id){
            this.$store.dispatch("addCount" , id)
            this.$store.state.totalbill = 0
            this.$store.getters.totalSell
        },
        minusCount : function(id){
            this.$store.dispatch("minusCount" , id)
            this.$store.state.totalbill = 0
            this.$store.getters.totalSell
        }
    },
    props:['id','imga','name','desc','time' , 'price' , 'count']
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
     .card{
      overflow:hidden;
      border:none !important;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    .card-title {
      color:#2C3E50  !important;
    }
    .card-text{
      opacity:0.8;
    }
    .price-menu{
      color:#2C3E50;
      font-weight:700;
      font-size:20px;
      opacity:0.5;
      bottom:0px;
      right:0px;
      transition:all 0.5s ease;
    }
    .price-menu svg{
      transform:scale(0.8)
    }
    .card:hover .price-menu{
      opacity:0.9;
      color:#FFB200;
    }
    .img-menu{
      height: 100% !important;
      object-fit: cover;
    }
    .btn-delete-order{
        color:#2C3E50
    }
    .btn-delete-order span > svg {
        transform:scale(0.7);
        fill:#2C3E50;
    }
    .btn-order-edit{
        background-color:#fff;
        width:50px;
        border-radius:25px;
        overflow:hidden;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    }
    .btn-order-edit span.plus:hover{
        cursor:pointer;
        background: linear-gradient(0deg, rgba(255,255,255,0.5242471988795518) 0%, rgba(255,178,0,1) 100%);
    }
    .btn-order-edit span.minus:hover{
        cursor:pointer;
        background: linear-gradient(180deg, rgba(255,255,255,0.5242471988795518) 0%, rgba(255,178,0,1) 100%);
    }
    .btn-order-edit span > svg{
        transform:scale(0.7);
        fill:#2C3E50;
    }
    .card-order{
        border-bottom:1px solid #2c3e5028;
        margin-bottom:10px;
    }
    @media only screen and (max-width: 549px){
     .btn-delete-order{width:50px !important}
    }
  </style>
  