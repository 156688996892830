<template>
    <div class="container" style="padding-top:50px;"> 
      <div class="p-3">
        <div class="row align-items-center ps-2">
          <div class="col-12 col-md-6 head-order">
            <h4>Hottest Demand</h4>
            <p> 
              Here are the most popular foods of the week.
            </p>
          </div>
          <div class="list-fillters col-12 col-md-6 text-end">
            <button class="btn-fillter" type="button" data-filter="all">All</button>
            <button class="btn-fillter" type="button" data-filter=".family">Family</button>
            <button class="btn-fillter" type="button" data-filter=".drinks">Drinks</button> 
            <button class="btn-fillter" type="button" data-filter=".meals">Meals</button> 
            <button class="btn-fillter" type="button" data-filter=".salades">Salades</button> 
          </div>
        </div> 
        
        <div class="container-trends row">
          <div class="col-12 col-md-6" v-for="trend in trends" :key="trend.id" >
            <CardTrend :id="trend.id" :name="trend.name" :cate="trend.cate" :price="trend.price" :imga="trend.imga" :desc="trend.desc" :count="trend.count" />
          </div>
        </div>
        
      </div>
    </div>
</template>
  
  <script>
    import mixitup from 'mixitup';
    import CardTrend from "../parts/CardTrend.vue"
  export default {
    name: 'Trend-Page',
    data(){
      return{
        trends : this.$store.getters.gettrend
      } 
    },
    components:{
      CardTrend
    },
    mounted(){
      mixitup('.container-trends')
    }
  }
  
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .head-order{
      padding: 20px 0px ;
    }
    .head-order h4{
      font-weight:300px;
      color:#2C3E50;
    }
    .head-order p{
      opacity:0.8;
      color:#2C3E50;
      font-size:14px;
    }
    .btn-fillter{
      border:none;
      background-color:#fff ;
      color:#2C3E50;
      margin-left:5px;
      border-radius:20px;
      transition:all 0.2s ease-in-out 0.1s;
      padding:6px 12px;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 0px 3px -3px, #ffb30086 0px -3px 0px inset;
    }
    .btn-fillter:hover{
      transform:scale(0.9)
    }
    @media only screen and (max-width: 549px){
      .list-fillters{
        margin:10px 0;
      }
      .btn-fillter{
        font-size: 14px !important;
        padding:4px 8px;
      }
    }
  </style>
  