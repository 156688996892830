<template>
    <router-link :to="link" class="part-nav">
        <div class="nav-block d-flex flex-column align-items-center justify-content-between">
            <span class="nav-title">{{title}}</span>
            <span class="nav-icon">
                <svg xmlns="http://www.w3.org/2000/svg" :fill="color" height="48" width="48"><path :d="icon"/></svg>
            </span>
        </div>
    </router-link>
</template>
  
  <script>
  export default {
    name: 'Link-Nav',
    data(){
      return{
      }
    },
    props:['link','color','title','icon']
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .parts-nav{
    height:50px
  }
  .part-nav{
    display:inline-block;
    width:80px;
    height:100%;
    position:relative;
    overflow: hidden;
  }
  .nav-block{
    position:absolute;
    width:100%;
    height:100px;
    top:12px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .nav-title{
    font-size:18px;
    color:white;
  }
  .nav-block:hover{
    top:-49px;
    background-color: #FFB200;
  }
  .nav-icon{
    transform:scale(0.6);
  }
  .router-link-exact-active .nav-block{
    top:-49px;
    background-color: #FFB200;
  }
  </style>
  