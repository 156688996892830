<template>
    <div class="container">
      <div class="container-menu d-flex align-items-center position-relative">
        <div class="book-menu">
          <div class="cover-menu">
            <img src="../../assets/cover.png" alt="">
          </div>
          <div class="details-menu">
            <div class="content-menu d-flex flex-column justify-content-between">
              <div class="body-menu">
                <h1>Menu</h1>
                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                    <CardMenu v-for="family in datafamily" :key="family.id" :id="family.id" :name="family.name" :desc="family.desc" :time="family.time" :imga="family.imga" :price="family.price" ></CardMenu>
                  </div>
                  <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                    <CardMenu v-for="meal in datameals" :key="meal.id" :id="meal.id" :name="meal.name" :desc="meal.desc" :time="meal.time" :imga="meal.imga" :price="meal.price" ></CardMenu>
                  </div>
                  <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
                    <CardMenu v-for="drink in datadrinks" :key="drink.name" :id="drink.id" :name="drink.name" :desc="drink.desc" :time="drink.time" :imga="drink.imga" :price="drink.price" ></CardMenu>
                  </div>
                  <div class="tab-pane fade" id="nav-salads" role="tabpanel" aria-labelledby="nav-salads-tab" tabindex="0">
                    <CardMenu v-for="salade in datasalades" :key="salade.name" :id="salade.id" :name="salade.name" :desc="salade.desc" :time="salade.time" :imga="salade.imga" :price="salade.price" ></CardMenu>
                  </div>
                </div>
              </div>
              
              <nav class="paginat-menu">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Family</button>
                  <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Meals</button>
                  <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Drinks</button>
                  <button class="nav-link" id="nav-salads-tab" data-bs-toggle="tab" data-bs-target="#nav-salads" type="button" role="tab" aria-controls="nav-salads" aria-selected="false">Salads</button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </template>
  
  <script>
    import CardMenu from '../parts/CardMenu.vue';
    export default {
    name: 'Menu-Page',
    components:{
      CardMenu,
    },
    data(){
      return{
          datafamily:this.$store.getters.getdata("family"),
          datameals:this.$store.getters.getdata("meals"),
          datadrinks:this.$store.getters.getdata("drinks"),
          datasalades:this.$store.getters.getdata("salades"),
      }
    },
    mounted(){
      // this.$store.state.countss = []
      this.$store.state.totalbill = 0
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .container-menu{
      padding-top:50px;
      height: 100vh;
      width:100%;
      overflow:hidden;
    }
    .book-menu{
      position: relative;
      width: 50%;
      height: 95%;
      left:45%;
      background-color: #fff;
      transform-style: preserve-3d;
      transform:perspective(2000px);
      transition: 1s;
      box-shadow: inset 300px 0 50px rgba(0, 0, 0, 0.15) , 0 20px 20px rgba(0, 0, 0, 0.15);
      cursor:pointer;
    }
    .details-menu{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
    }
    .cover-menu{
        z-index: 2;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        transform-style: preserve-3d;
        overflow: hidden;
        transition: 1s ease-in-out;
        transform-origin: left;
        border-left:2px solid #FFB200;
        
    }
    .cover-menu img{
        width:100%;
        height:100%;
        z-index: 1;
        transform:scale(0);
        transition:all 0.5s ease-in-out 1s;
    }
    .cover-menu::before{
        content: "";
        position: absolute;
        width: 10px;
        height: 150%;
        background-color: #fff;
        transform: rotate(36.5deg);
        box-shadow: 0 0 0 20px #FFB200;
        transition: 0.5s;
        transition-delay: 1s;
    }
    .book-menu:hover .cover-menu::before{
        width: 0;
        box-shadow: 0 0 0 250px #FFB200;
        transform: rotate(143.5deg);
    }
    .book-menu:hover .cover-menu{
        transform: rotateY(-180deg); /* it is open book */
    }
    .book-menu:hover .cover-menu img{
      transform:scale(1); 
    }
    .book-menu:hover{
        transform: translateX(5%);
        box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.15) , 0 10px 10px rgba(0, 0, 0, 0.15); /*this is shadow be to open book*/ 
    }
    .content-menu{
      padding: 20px;
      height:100%;
    }
    .body-menu h1{
      font-weight:300;
      color:#2C3E50;
      font-size:28px;
      border-bottom: 1px solid #ffb3002f;
      padding-bottom:10px;
    }
    .paginat-menu > div .nav-link{
      color:#2C3E50 !important;
    }
    .paginat-menu > div .nav-link.active{
      color:#FFB200 !important;
    }
    .body-menu{
      padding-right:10px;
      overflow-x: auto !important;
    }
    @media only screen and (min-width: 550px) and (max-width: 767px){
      .book-menu{
        width: 100%;
        height: 95%;
        left:0%;
      }
    }
    @media only screen and (max-width: 549px){
      .book-menu{
        width: 100%;
        height: 95%;
        left:0%;
      }
      .nav-link{
        font-size: 12px !important
      }
    }
  </style>
  