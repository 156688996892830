<template>
    <div class="container" style="padding-top:50px;">
      <div class="row">
        <div class="col-12 col-lg-8"> 
          <div class="head-order">
            <h4>What you ordered</h4>
            <p> 
              Thank you for using our application. From here, you can delete orders or modify the quantity before the final order.
            </p>
          </div>   
          <div class="pb-4">
              <CardOrder v-for="cart in carts" :key="cart.id"  :id="cart.id" :count="cart.count" :name="cart.name" :desc="cart.desc" :time="cart.time" :imga="cart.imga" :price="cart.price" />      
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="bg-white w-100 h-100 position-relative">
            <div class="position-sticky p-2" style="top:10px;">
              <div class="head-bill pt-4 d-flex justify-content-between">
                <h5>Bill</h5> 
                <span class="opacity-75">NO:10654855</span>
              </div>
              <div class="pt-4">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Count</th>
                      <th scope="col">Totle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ListBill v-for="cart in carts" :key="cart.id" :name="cart.name"  :price="cart.price" :count="cart.count"  />
                  </tbody>
                </table> 
              </div>
              <div class="text-end">
                <span class="opacity-75">{{currentDate()}}</span>
              </div>
              <div class="pt-2 d-flex flex-column">
                <span class="total-bill"><b>Sum Order :</b> {{this.$store.state.totalbill}} </span>
                <span class="total-bill"><b>Discount :</b> {{(this.$store.state.totalbill * this.$store.state.discount)}} </span>
                <span class="total-bill"><b>Total Bill :</b> {{this.$store.state.totalbill - (this.$store.state.totalbill * this.$store.state.discount)}} </span>
              </div>
              <div>
                <button class="mt-5 btn btn-success form-control">Order</button>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </template>
  
  <script>
    import CardOrder from '../parts/CardOrder.vue';
    import ListBill from '../parts/ListBill.vue';
   
    
  export default {
    name: 'Order-Page',
    data(){
      return{
        carts:this.$store.state.carts
      } 
    },
    components:{
        CardOrder,
        ListBill
    },
    methods:{
      currentDate() {
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
        return date;
      }
    },
    mounted(){
      this.$store.getters.totalSell
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .head-order{
      padding: 20px 0px ;
    }
    .head-order h4{
      font-weight:300px;
      color:#2C3E50;
    }
    .head-order p{
      opacity:0.8;
      color:#2C3E50;
      font-size:14px;
    }
    .category{
      color:#2C3E50;
      text-transform: capitalize;
      padding-bottom:15px;
    }
    .head-bill h5,
    .head-bill span{
      color:#2C3E50;
    }
    .head-bill{
      border-bottom: 2px solid #2C3E50;
    }
    .total-bill{
      color:#2C3E50;
      margin-top:5px;
    }
  </style>
  