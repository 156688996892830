<template>
    <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false"  tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">Fast Resturant</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div class="descrption-order">
            Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
          </div>
          <div class="dropdown mt-3">
            <ul class="list-group list-group-flush">
              <li v-if="this.$store.getters.showPointCatrs" class="list-menu list-group-item">
                <router-link @click="resetcart" class="link-side d-flex align-items-center" to="/order-food">
                  <div>
                    <span class="icon-list-menu">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M28.75 29.8q-.9-2.55-3.7-4.1-2.8-1.55-7.85-1.55T9.35 25.7q-2.8 1.55-3.75 4.1ZM2 32.8q0-2.4.875-4.525.875-2.125 2.75-3.7 1.875-1.575 4.75-2.5t6.875-.925q3.95 0 6.8.925 2.85.925 4.725 2.5 1.875 1.575 2.75 3.7T32.4 32.8Zm0 6.4v-3h30.4v3ZM35.4 46v-3h4.3l2.85-29h-19.9l-.35-3h10.25V2h3v9H46l-3.35 32q-.15 1.3-1.075 2.15-.925.85-2.325.85Zm0-3h4.3-4.3ZM3.5 46q-.6 0-1.05-.45Q2 45.1 2 44.5V43h30.4v1.5q0 .6-.45 1.05-.45.45-1.05.45Zm13.7-16.2Z"/></svg>
                    </span>
                    <span class="label-text ps-2">Order</span>
                  </div>
                  <span class="box-icon position-absolute"></span>
                </router-link>
              </li>
              <li v-if="this.$store.getters.showPointCatrs == false" class="list-menu list-group-item opacity-50">
                <router-link class="link-side d-flex align-items-center" to="/">
                  <div>
                    <span class="icon-list-menu">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M28.75 29.8q-.9-2.55-3.7-4.1-2.8-1.55-7.85-1.55T9.35 25.7q-2.8 1.55-3.75 4.1ZM2 32.8q0-2.4.875-4.525.875-2.125 2.75-3.7 1.875-1.575 4.75-2.5t6.875-.925q3.95 0 6.8.925 2.85.925 4.725 2.5 1.875 1.575 2.75 3.7T32.4 32.8Zm0 6.4v-3h30.4v3ZM35.4 46v-3h4.3l2.85-29h-19.9l-.35-3h10.25V2h3v9H46l-3.35 32q-.15 1.3-1.075 2.15-.925.85-2.325.85Zm0-3h4.3-4.3ZM3.5 46q-.6 0-1.05-.45Q2 45.1 2 44.5V43h30.4v1.5q0 .6-.45 1.05-.45.45-1.05.45Zm13.7-16.2Z"/></svg>
                    </span>
                    <span class="label-text ps-2">Order</span>
                  </div>
                  <span class="box-icon position-absolute"></span>
                </router-link>
              </li>
              <li v-if="this.$store.getters.showPointCatrs" class="list-group-item">
                <router-link  @click="resetcart" class="link-side d-flex align-items-center" to="/delivery-food">
                  <div>
                    <span class="icon-list-menu">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M11.2 39.95q-2.45 0-4.175-1.725Q5.3 36.5 5.3 34.05H2V11q0-1.2.9-2.1Q3.8 8 5 8h28.95v8.35h5.25L46 25.4v8.65h-3.55q0 2.45-1.725 4.175Q39 39.95 36.55 39.95q-2.45 0-4.175-1.725Q30.65 36.5 30.65 34.05H17.1q0 2.45-1.725 4.175Q13.65 39.95 11.2 39.95Zm0-3q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85ZM5 31.05h1.1q.85-1.35 2.15-2.15 1.3-.8 2.9-.8 1.6 0 2.925.825 1.325.825 2.175 2.125h14.7V11H5Zm31.55 5.9q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85Zm-2.6-10.2h9.3l-5.55-7.4h-3.75ZM18 21.55Z"/></svg>
                    </span>
                    <span class="label-text ps-2">Order & Delivery</span>
                  </div>
                  <span class="box-icon position-absolute"></span>
                </router-link>
              </li>
              <li v-if="this.$store.getters.showPointCatrs == false" class="list-menu list-group-item opacity-50">
                <router-link class="link-side d-flex align-items-center" to="/">
                  <div>
                    <span class="icon-list-menu">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M11.2 39.95q-2.45 0-4.175-1.725Q5.3 36.5 5.3 34.05H2V11q0-1.2.9-2.1Q3.8 8 5 8h28.95v8.35h5.25L46 25.4v8.65h-3.55q0 2.45-1.725 4.175Q39 39.95 36.55 39.95q-2.45 0-4.175-1.725Q30.65 36.5 30.65 34.05H17.1q0 2.45-1.725 4.175Q13.65 39.95 11.2 39.95Zm0-3q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85ZM5 31.05h1.1q.85-1.35 2.15-2.15 1.3-.8 2.9-.8 1.6 0 2.925.825 1.325.825 2.175 2.125h14.7V11H5Zm31.55 5.9q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85Zm-2.6-10.2h9.3l-5.55-7.4h-3.75ZM18 21.55Z"/></svg>
                    </span>
                    <span class="label-text ps-2">Order & Delivery</span>
                  </div>
                  <span class="box-icon position-absolute"></span>
                </router-link>
              </li>
              <li class="list-group-item">
                <router-link @click="resetcart" class="link-side d-flex align-items-center" to="/trend-food">
                  <div>
                    <span class="icon-list-menu">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M6.15 36 4 33.85 18.6 19.3l8.35 8.35L39 15.6h-6.35v-3H44v11.35h-2.95V17.8L26.9 31.95l-8.35-8.35Z"/></svg>
                    </span>
                    <span class="label-text ps-2">Trending Order</span>
                  </div>
                  <span class="box-icon position-absolute"></span>
                </router-link>
              </li>
              <li class="list-group-item">
                <router-link  @click="resetcart" class="link-side d-flex align-items-center" to="/random-food">
                  <div>
                    <span class="icon-list-menu">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M29.05 40.5v-3h6.25l-9.2-9.15 2.1-2.15 9.3 9.2v-6.35h3V40.5Zm-19.45 0-2.1-2.15 27.9-27.9h-6.35v-3H40.5V18.9h-3v-6.3Zm10.15-18.7L7.5 9.6l2.15-2.15 12.25 12.2Z"/></svg>
                    </span>
                    <span class="label-text ps-2">Random Order</span> 
                  </div>
                  <span class="box-icon position-absolute"></span>
                </router-link>
              </li>
              <!-- <li class="list-group-item">
                <router-link @click="resetcart" class="link-side d-flex align-items-center" to="/order-food">
                  <div>
                    <span class="icon-list-menu">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5ZM24 28q-.85 0-1.425-.575Q22 26.85 22 26q0-.85.575-1.425Q23.15 24 24 24q.85 0 1.425.575Q26 25.15 26 26q0 .85-.575 1.425Q24.85 28 24 28Zm-8 0q-.85 0-1.425-.575Q14 26.85 14 26q0-.85.575-1.425Q15.15 24 16 24q.85 0 1.425.575Q18 25.15 18 26q0 .85-.575 1.425Q16.85 28 16 28Zm16 0q-.85 0-1.425-.575Q30 26.85 30 26q0-.85.575-1.425Q31.15 24 32 24q.85 0 1.425.575Q34 25.15 34 26q0 .85-.575 1.425Q32.85 28 32 28Zm-8 8q-.85 0-1.425-.575Q22 34.85 22 34q0-.85.575-1.425Q23.15 32 24 32q.85 0 1.425.575Q26 33.15 26 34q0 .85-.575 1.425Q24.85 36 24 36Zm-8 0q-.85 0-1.425-.575Q14 34.85 14 34q0-.85.575-1.425Q15.15 32 16 32q.85 0 1.425.575Q18 33.15 18 34q0 .85-.575 1.425Q16.85 36 16 36Zm16 0q-.85 0-1.425-.575Q30 34.85 30 34q0-.85.575-1.425Q31.15 32 32 32q.85 0 1.425.575Q34 33.15 34 34q0 .85-.575 1.425Q32.85 36 32 36Z"/></svg>
                    </span>
                    <span class="label-text ps-2">Scheduled Order</span>
                  </div>
                  <span class="box-icon position-absolute"></span> 
                </router-link>
              </li> -->
              <!-- <li class="list-group-item">
                <router-link @click="resetcart" class="link-side d-flex align-items-center" to="/favorite-food">
                  <div class="position-relative">
                    <span class="icon-list-menu">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M10 42V8.75q0-1.2.9-2.1.9-.9 2.1-.9h14.5v3H13v28.7l11-4.65 11 4.65v-16.7h3V42l-14-6Zm3-33.25h14.5H24Zm22 9v-4.5h-4.5v-3H35v-4.5h3v4.5h4.5v3H38v4.5Z"/></svg>
                    </span>
                    <span class="label-text ps-2">Favorite Orders</span> 
                  </div>
                  <span class="box-icon position-absolute"></span>
                </router-link>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  
  export default {
    name: 'Slider-Part',
    data(){
      return{
        
      }
    },
    methods:{
      resetcart: function(){
        this.$store.state.totalbill = 0
      }
    }
  }
  </script>
  
  <style>
    
    .offcanvas-title{
      color:#2C3E50 !important;
    }
    .descrption-order{
      color:#2C3E50 !important;
      opacity: 0.7;
    }
    .link-side{
      text-decoration: none !important;
      width:100%;
      display:inline-block;
      position:relative;
    }
    .icon-list-menu svg{
      transform:scale(0.8)
    }
    .box-icon{
      top:0px;
      left:0px;
      height:100%;
      width:0%;
      background: linear-gradient(270deg, rgba(255,255,255,0.5242471988795518) 0%, rgba(255,178,0,1) 100%);
      z-index: 1;
      transition: all 0.2s ease-out;
    }
    .link-side > div{
      z-index: 999;
    }
    .link-side:hover .box-icon{
      width:100%;
    }
    .label-text{
      color:#2C3E50 !important;
      font-weight:500;
    }
  </style>
  