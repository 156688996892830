import { createRouter , createWebHistory } from 'vue-router'


import Home from "./components/pages/Home.vue"
import About from "./components/pages/About.vue"
import Menu from "./components/pages/Menu.vue"
import OrderPage from "./components/pages/Order.vue"
import DeliveryPage from "./components/pages/Delivery.vue"
import TrendPage from "./components/pages/Trend.vue"
import RandPage from "./components/pages/Random.vue"

const router = createRouter({
    history:createWebHistory(),
    routes:[
        {
            path:"/" , name:"Home" , component:Home
        },
        {
            path:"/about" , name:"About" , component:About
        },
        {
            path:"/menu" , name:"Menu" , component:Menu
        },
        {
            path:"/order-food" , name:"orderFood" , component:OrderPage
        },
        {
            path:"/delivery-food" , name:"deliveryFood" , component:DeliveryPage
        },
        {
            path:"/trend-food" , name:"trendFood" , component:TrendPage
        },
        {
            path:"/random-food" , name:"randomFood" , component:RandPage
        }
    ]
})

export default router