<template>
    <div class="container" style="padding-top:50px;">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="container-about d-flex flex-column justify-content-center align-items-center">
            <h1>
              Name Resturant
            </h1>
            <p class="lead p-about" style="text-align:justify">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum quae non placeat quis corrupti doloremque, et rem est autem sit accusantium quod culpa, repudiandae, hic quibusdam maiores. Quas, voluptate. Laudantium.
            </p>
            <div class="owners  w-100">
              <h3>Restaurant Owners</h3>
              <div class="d-flex  w-100 flex-column flex-sm-row gap-1">
                <div class="card-owner  position-relative">
                  <img src="../../assets/owners/1.png" />
                  <p class="info-owner position-absolute">
                    i,m saly , from turky , 27years , i,m women bussines
                  </p>
                </div>
                <div class="card-owner position-relative">
                  <img src="../../assets/owners/2.png" />
                  <p class="info-owner position-absolute">
                    i,m saly , from turky , 27years , i,m women bussines
                  </p>
                </div>
                <div class="card-owner position-relative">
                  <img src="../../assets/owners/3.png" />
                  <p class="info-owner position-absolute">
                    i,m saly , from turky , 27years , i,m women bussines
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="container-contact d-flex flex-column justify-content-center align-items-start">
            <ul class="infos-contact w-100 list-group list-group-flush">
              <li class="info-contact overflow-hidden w-100 list-group-item">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48"><path d="M39.75 42q-6.1 0-12.125-3T16.8 31.2Q12 26.4 9 20.375 6 14.35 6 8.25q0-.95.65-1.6Q7.3 6 8.25 6h7q.7 0 1.225.475.525.475.675 1.275l1.35 6.3q.1.7-.025 1.275t-.525.975l-5 5.05q2.8 4.65 6.275 8.1Q22.7 32.9 27.1 35.3l4.75-4.9q.5-.55 1.15-.775.65-.225 1.3-.075l5.95 1.3q.75.15 1.25.75T42 33v6.75q0 .95-.65 1.6-.65.65-1.6.65Zm-28.3-23.4 4.05-4.1L14.35 9H9q0 1.95.6 4.275t1.85 5.325ZM29.9 36.75q2.05.95 4.45 1.55 2.4.6 4.65.7v-5.35l-5.15-1.05ZM11.45 18.6ZM29.9 36.75Z"/></svg>
                  </span>
                  <label class="ps-2">0595984888</label>
              </li>
              <li class="info-contact w-100 list-group-item">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#2C3E50" height="48" width="48" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
                </span>
                <label class="ps-2">0595984888</label>
              </li>
            </ul>
            <div class="locations bg-white img-thumbnail">
              <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13479.743498516542!2d35.07451045134275!3d32.36726573285038!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sar!2s!4v1663101377766!5m2!1sar!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'About-Page',
    data(){
      return{
        
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .card-owner{
      max-width:180px;
      height: 200px;
      background-color: white;
      cursor:pointer;
      overflow:hidden;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    .container-contact,
    .container-about{
      height: calc(100vh - 50px);
    }
    .container-about h1{
      color:#2C3E50;
      width:100%;
      text-align: start !important;
      font-weight:400;
    }
    .owners h3{
      color:#2C3E50;
      font-weight:400;
    }
    .card-owner img{
      object-fit: cover;
      transition: all 0.3s ease;
      width:100% !important;
      height:100% !important;
    }
    .card-owner:hover img{
      transform-origin: top;
      transform: scale(0.5);
    }
    .info-owner{
      bottom:0px;
      left:-200px;
      transition: transform 0.3s ease 0.4s , opacity 0.3s ease  0.2s;
      width:100%;
      color:#2C3E50;
      opacity: 0;
    }
    .card-owner:hover .info-owner{
      left:20px;
      opacity: 1;
    }
    .locations{
      margin-top:10px;
      width: 100%;
      height:300px;
    }
    .locations iframe{
      width: 100% !important;
      height: 100% !important;;
    }
    .info-contact span{
      background-color:#FFB200;
      display:inline-block;
      height:100%;
      border-radius:4px;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
    .info-contact span svg{
      transform:scale(0.6);
    }
    @media only screen and (max-width: 549px){
      .container-about{
        height: 100%;
        padding-top:50px !important;
        text-align:center;
      }
      .info-owner{
        padding-right:30px
      }
      .container-about h1{
        text-align: center !important;
      }
      .container-about > p.lead{
        border-bottom:2px solid #2C3E50;
        padding-bottom:40px;
      }
      .owners{
        border-bottom:2px solid #2C3E50;
        padding-bottom:40px;
      }
      .card-owner{
        max-width:100% !important;
      }
      .p-about{font-size:16px !important}
    }
  </style>
  