import { createStore } from 'vuex'

const store = createStore({
    state(){
        return{
          // هون الافضل هو عمل داتا تحتوي على الكتجري بدل ما تكتب فاملي و ميل .. الخ على شكل اراي بتعملهم كلهم في داتا وهاي الداتا بتحتوي نوع الكتجري
            datameales : [
                {
                  id:"f-1",
                  count:"1",
                  cate:"family",
                  name:"Shawarma one",
                  imga:"https://img.freepik.com/free-photo/side-view-chicken-doner-with-greens-cucumber-tomato-sauce-cucumber-red-onion-pita-slice-lemon-board_141793-4824.jpg",
                  time:"10 mins",
                  price:"60",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"f-2",
                  count:"1",
                  cate:"family",
                  name:"Shawarma tow",
                  imga:"https://img.freepik.com/free-photo/side-view-chicken-doner-grilled-chicken-with-cucumber-tomato-greens-red-onion-sauce-bread_141793-4822.jpg",
                  time:"7 mins",
                  price:"40",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"f-3",
                  count:"1",
                  cate:"family",
                  name:"Shawarma three",
                  imga:"https://img.freepik.com/free-photo/chicken-doner-with-gren-salad-french-fries_141793-1369.jpg",
                  time:"5 mins",
                  price:"50",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"f-4",
                  count:"1",
                  cate:"family",
                  name:"Shawarma four",
                  imga:"https://img.freepik.com/free-photo/front-view-chicken-doner-pita-bread-with-ketchup-mayonnaise-french-fries-vegetable-salad-board_141793-2822.jpg",
                  time:"9 mins",
                  price:"90",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"m-4",
                  count:"1",
                  cate:"meals",
                  name:"Shawarma four",
                  imga:"https://img.freepik.com/free-photo/front-view-chicken-doner-pita-bread-with-ketchup-mayonnaise-french-fries-vegetable-salad-board_141793-2822.jpg",
                  time:"9 mins",
                  price:"90",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"m-3",
                  count:"1",
                  cate:"meals",
                  name:"Shawarma three",
                  imga:"https://img.freepik.com/free-photo/chicken-doner-with-gren-salad-french-fries_141793-1369.jpg",
                  time:"5 mins",
                  price:"50",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"m-1",
                  count:"1",
                  cate:"meals",
                  name:"Shawarma one",
                  imga:"https://img.freepik.com/free-photo/side-view-chicken-doner-with-greens-cucumber-tomato-sauce-cucumber-red-onion-pita-slice-lemon-board_141793-4824.jpg",
                  time:"10 mins",
                  price:"60",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"m-2",
                  count:"1",
                  cate:"meals",
                  name:"Shawarma tow",
                  imga:"https://img.freepik.com/free-photo/side-view-chicken-doner-grilled-chicken-with-cucumber-tomato-greens-red-onion-sauce-bread_141793-4822.jpg",
                  time:"7 mins",
                  price:"40",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"d-1",
                  count:"1",
                  cate:"drinks",
                  name:"Soda Black",
                  imga:"https://img.freepik.com/premium-photo/glass-fruit-water-wooden-table-beach-cafe-sunset-sea_162585-48.jpg",
                  time:"0 mins",
                  price:"2",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"d-2",
                  count:"1",
                  cate:"drinks",
                  name:"Soda Black",
                  imga:"https://img.freepik.com/free-photo/morning-with-turkish-coffee-brewing_1157-8840.jpg",
                  time:"0 mins",
                  price:"2",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"d-3",
                  count:"1",
                  cate:"drinks",
                  name:"Soda Black",
                  imga:"https://img.freepik.com/premium-photo/cola-glass-ice-cubes-wooden-background_51137-1912.jpg?",
                  time:"0 mins",
                  price:"2",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"d-4",
                  count:"1",
                  cate:"drinks",
                  name:"Soda Black",
                  imga:"https://img.freepik.com/free-psd/different-fruit-smoothies-glasses-front-view_23-2148828836.jpg",
                  time:"0 mins",
                  price:"2",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"s-1",
                  count:"1",
                  cate:"salades",
                  name:"salade one",
                  imga:"https://img.freepik.com/free-photo/front-view-greece-salad-sliced-vegetable-salad-with-tomatoes-cucumbers-white-cheese-olives-inside-white-plate-vitamine-vegetables_140725-16048.jpg",
                  time:"4 mins",
                  price:"2",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"s-2",
                  count:"1",
                  cate:"salades",
                  name:"salade one",
                  imga:"https://img.freepik.com/free-photo/top-view-delicious-vegan-salad-plate-with-various-fresh-vegetables-dark-background_179666-47279.jpg",
                  time:"4 mins",
                  price:"2",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"s-3",
                  count:"1",
                  cate:"salades",
                  name:"salade one",
                  imga:"https://img.freepik.com/free-photo/flat-lay-salad-with-chicken-sesame-seeds_23-2148700369.jpg",
                  time:"4 mins",
                  price:"2",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                },
                {
                  id:"s-4",
                  count:"1",
                  cate:"salades",
                  name:"salade one",
                  imga:"https://img.freepik.com/free-photo/salad-from-tomatoes-cucumber-red-onions-lettuce-leaves-healthy-summer-vitamin-menu-vegan-vegetable-food-vegetarian-dinner-table-top-view-flat-lay_2829-6482.jpg",
                  time:"4 mins",
                  price:"2",
                  desc:"This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                }
            ],
            trend:[
              {
                idmeal:"f-1",
                countsell:"130",
              },
              {
                idmeal:"m-1",
                countsell:"150",
              },
              {
                idmeal:"d-1",
                countsell:"100",
              },
              {
                idmeal:"s-2",
                countsell:"70",
              }
            ],
            carts : [],
            totalbill: 0,
            deliverycost:5,
            statusOpen:true,
            user:{"id" : 1 , "level":"gold" , "isadmin":false},
            randomvalue:[] , 
            discount:0.1                 
        }
    },
    actions:{
      getid:(context,payload) => {
        context.commit( "getid" , payload )
      },
      deleteItem:(context,payload)=>{
        context.commit( "deleteItem" , payload )
      },
      addCount:(context , payload)=>{
        context.commit( "addCount" , payload )
      },
      minusCount:(context , payload)=>{
        context.commit( "minusCount" , payload )
      },
      resetrandom:(context)=>{
        context.commit( "resetrandom")
      }
    },
    mutations:{
      getid:(state , id)=>{
        const item = state.datameales.find(item => item.id == id);
        const itemCheck = state.carts.find(item => item.id == id);
        if(!itemCheck){
          state.carts.push(item)
        }
      },
      deleteItem:(state , id) =>{
        const index = state.carts.findIndex(i => i.id == id);
        if(index > -1){
          state.carts.splice(index , 1)
        }
      },
      addCount:(state , id)=>{
        let addcount = state.carts.find(item => item.id == id);
        addcount.count++
      },
      minusCount:(state , id)=>{
        let minusChoose = state.carts.find(item => item.id == id);
        if(minusChoose.count > 1){
         minusChoose.count--;
        } 
      },
      resetrandom:(state)=>{
        state.randomvalue = []
      }
    },
    getters:{
      getdata:(state) => (cate) => {
        let data =  state.datameales.filter( f => f.cate == cate)
        return data;
      },
      totalSell:(state)=>{
        state.carts.forEach(e => {
          state.totalbill += (e.count * e.price)
        })
      },
      showPointCatrs:(state)=>{
        return state.carts.length > 0
      },
      levelUser:(state)=>{
        switch (state.user.level) {
          case "gold":
            return "gold"
          case "selver":
            return "selver"
          case "platenum":
            return "platenum"
          default:
            return "gest"
        }
      },
      gettrend:(state)=>{
        let trends = [] 
        state.trend.map(e => {
          trends.push(state.datameales.find(item => item.id == e.idmeal))
        })
        return trends
      },
      randFood:(state)=>{
        let checkitem = [] 
        let x = 4
        for(let i = 1 ; i <= x ; i++){
          const index = Math.ceil(state.datameales.length * Math.random());
          if(!checkitem.includes(index)){
            state.randomvalue.push(state.datameales[index])
          }else{
            x++
          }
          checkitem.push(index)
        }
        checkitem = []
      }

    }
})

export default store