<template>
    <div class="container">
      <div  class="go-order overflow-hidden text-center d-flex flex-column justify-content-center align-items-center position-relative">
        <div @click="resetcart" class="row justify-content-center align-items-center home-head w-100">
            <div class="position-relative title-home">
              <h1>Welcome To Your Restorant</h1>
              <p class="deschome">
                We are a group of restaurants striving to offer our best to make our customers happy and offer them the best of our best quality.
              </p>
              <p class="infocontact my-2">
                To contact and inquire <span>0594215841/02541851</span>  or  <router-link to="/about">Here</router-link>  
              </p>
           
              <router-link to="/menu"  class="order-btn my-3 d-inline-block">
                Order Now
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path fill="#2c3e50" d="M24 44q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05 12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q3.75 0 7 1.2t5.85 3.3l-2.15 2.15q-2.2-1.75-4.9-2.7Q27.1 7 24 7q-7.25 0-12.125 4.875T7 24q0 7.25 4.875 12.125T24 41q1.85 0 3.575-.35T30.9 39.6l2.25 2.3q-2.05 1-4.35 1.55T24 44Zm14.5-4.5v-6h-6v-3h6v-6h3v6h6v3h-6v6Zm-17.45-6.4-8.25-8.3 2.25-2.25 6 6 20.7-20.7 2.3 2.25Z"/></svg>
                </span>
              </router-link>
      
            </div>
        </div>
        <div class="row home-info justify-content-between">
          <transition name="fadeone" appear>
            <div class="col-3"> 
              <div class="card-home d-flex justify-content-center align-items-center flex-column mx-auto">
                <span> 
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path fill="#2C3E50" d="M24 35.85Zm16.25-.9q1.35.7 2.275-.45.925-1.15.225-2.4l-3.55-6.7-2.7 7.35Zm-10.6-2.1h3.5L38 20.75q.3-.7.225-1.375-.075-.675-.825-1.025l-4.15-1.8q-.7-.35-1.4 0t-.75 1.05Zm-14.8 0h3.45L16.9 17.6q-.05-.75-.675-1.1-.625-.35-1.475.05l-3.95 1.7q-.65.25-.725 1.125-.075.875.175 1.575Zm-7.1 2.1 3.75-2.2-2.7-7.35-3.55 6.95q-.7 1.4.3 2.275t2.2.325Zm13.55-2.1h5.35l1.65-18.2q.1-.7-.375-1.175Q27.45 13 26.75 13h-5.5q-.8 0-1.2.475-.4.475-.35 1.175ZM6.65 38q-1.8 0-3.225-1.325T2 33.25q0-.65.225-1.2.225-.55.475-1.1L7.3 22q-.85-2.25-.175-4.05.675-1.8 2.425-2.55l4.15-1.7q.85-.35 1.65-.35.8 0 1.5.4.4-1.75 1.6-2.75 1.2-1 2.8-1h5.5q1.6 0 2.8.9 1.2.9 1.6 2.65.55-.25 1.425-.225.875.025 1.675.375l4.2 1.7q1.8.7 2.5 2.525T40.7 21.7l4.6 9.2q.15.45.425 1.05.275.6.275 1.1 0 2.55-1.75 3.75Q42.5 38 40.9 38q-.45 0-.95-.2-.5-.2-1.05-.45l-3.05-1.5H12.1l-2.9 1.5q-.5.25-1.15.45-.65.2-1.4.2Z"/></svg>
                </span>
                <span class="item-info d-inline-block fw-normal fs-4">Happy</span>
              </div>
            </div>
        </transition>
        <transition name="fadetow" appear>
          <div class="col-3"> 
            <div class="card-home d-flex justify-content-center align-items-center flex-column mx-auto">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path fill="#2C3E50" d="m8.25 42-2.1-2.1 21-21q-1.1-2.4-.5-4.975.6-2.575 2.85-4.825 2.25-2.2 5.45-2.825T40.2 7.7q2.1 2.1 1.425 5.25T38.6 18.5q-2.05 2.1-4.575 2.75-2.525.65-4.725-.3l-3.35 3.35 15.6 15.6-2.1 2.1-15.6-15.6Zm6.25-17.55L8.55 18.5Q6 15.95 5.9 12.45q-.1-3.5 2.3-6.2L20.45 18.5Z"/></svg>
              </span>
              <span class="item-info d-inline-block fw-normal fs-4">Proficiency</span>
            </div>
          </div>
        </transition>
        <transition name="fadethree" appear>
          <div class="col-3"> 
            <div class="card-home d-flex justify-content-center align-items-center flex-column mx-auto">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path fill="#2C3E50" d="M28.75 29.8q-.9-2.55-3.7-4.1-2.8-1.55-7.85-1.55T9.35 25.7q-2.8 1.55-3.75 4.1ZM2 32.8q0-2.4.875-4.525.875-2.125 2.75-3.7 1.875-1.575 4.75-2.5t6.875-.925q3.95 0 6.8.925 2.85.925 4.725 2.5 1.875 1.575 2.75 3.7T32.4 32.8Zm0 6.4v-3h30.4v3ZM35.4 46v-3h4.3l2.85-29h-19.9l-.35-3h10.25V2h3v9H46l-3.35 32q-.15 1.3-1.075 2.15-.925.85-2.325.85Zm0-3h4.3-4.3ZM3.5 46q-.6 0-1.05-.45Q2 45.1 2 44.5V43h30.4v1.5q0 .6-.45 1.05-.45.45-1.05.45Zm13.7-16.2Z"/></svg>
              </span>
              <span class="item-info d-inline-block fw-normal fs-4">Fast</span>
            </div>
           </div>
          </transition>
          <transition name="fadefour" appear>
          <div class="col-3"> 
            <div class="card-home d-flex justify-content-center align-items-center flex-column mx-auto">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path fill="#2C3E50" d="M14.25 44V25.6q-2.6-.55-4.425-2.625Q8 20.9 8 18V4h3v14h3.25V4h3v14h3.25V4h3v14q0 2.9-1.825 4.975Q19.85 25.05 17.25 25.6V44ZM35 44V28h-5.75V12.75q0-3.95 2.4-6.35Q34.05 4 38 4v40Z"/></svg>
              </span>
              <span class="item-info d-inline-block fw-normal fs-4">Taste</span> 
            </div>
          </div>
        </transition>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    
  export default {
    
    name: 'Home-Page',
    data(){
      return{
        x:'yazan',
        y:'masd',
        xx:true
      }
    },
    methods:{
      resetcart: function(){
        this.$store.state.totalbill = 0
      } 
    },
    mounted(){

    }
   
    
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .go-order {height: 100vh;}
    .home-head{height: 70%;width: 100%;}
    .home-info{width: 100%;height: 30%;}  
    .item-info{color:#2C3E50;}
    .order-btn{
      position: relative;
      border:none;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 4px;
      padding-bottom: 4px;
      background-color: #fff;
      border-top-left-radius: 50px;
      border-bottom-right-radius: 50px;
      color:#FFB200;
      
      text-decoration: none !important;
    }
    .logo-res{fill:#FFB200;}
    .order-btn > span > svg{transform:scale(0.8)}
    .order-btn::after{
      content: " ";
      position: absolute;
      width: 102%;
      height:102%;
      border-top-left-radius: 50px;
      border-bottom-right-radius: 50px;
      top:-2px;
      left:-5px;
      z-index: -1;
      background-color: #FFB200;
    }
    .card-home{
      width:70%;
      height:70%;
      border-bottom:2px solid #2C3E50;
      border-radius: 30px;
      cursor:pointer;
      position: relative;
      transition: all 0.3s ease ;
    }
    .title-home{
      width:50%;
      padding-top:80px;
    }
    .title-home h1{
      color:#2C3E50;
      font-weight:400;
      font-size:40px;
    }
   
    .deschome{
      font-size:15px;
      opacity:0.9;
      color:#2C3E50;
    }
    .infocontact{
      color:#2C3E50;
      opacity:0.9;
      font-size:15px;
    }
    .infocontact a{
      color:#FFB200;
    }
    .card-home::before{
      content: " ";
      position: absolute;
      width:3px;
      height:40px;
      background: linear-gradient(0deg, rgba(238,174,202,0) 0%, rgba(44,62,80,1) 65%);
      bottom:-40px;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
    }
   
    @media only screen and (min-width: 768px) and (max-width: 991px){
      .title-home h1{font-size:30px;}
      .title-home{width:60%;}
    }
    @media only screen and (min-width: 550px) and (max-width: 767px){
      .title-home h1{font-size:30px;}
      .title-home{width:100%;}
    }
    @media only screen and (max-width: 549px){
      .title-home h1{
      font-weight:300;
      font-size:30px;
      }
      .title-home{
        width:100%;
        height:50%;
        padding-top:0px;
      }
      .card-home::before{display:none;}
      .card-home{  border-bottom:none;}
      .item-info{font-size: 18px !important;}
      .order-btn{
        padding:4px 30px;
      }
      .order-btn > span > svg{transform:scale(0.6)}
      .card-home > span:first-of-type  svg{transform:scale(0.8)}
    }
    .fadeone-enter-from{transform: translateY(400px);}
    .fadeone-enter-to{transform: translateY(0px);}
    .fadeone-enter-active{transition: all 0.3s ease-out;}

    .fadetow-enter-from{transform: translateY(400px);}
    .fadetow-enter-to{transform: translateY(0px);}
    .fadetow-enter-active{transition: all 0.3s ease-out 0.3s ;}

    .fadethree-enter-from{transform: translateY(400px);}
    .fadethree-enter-to{transform: translateY(0px);}
    .fadethree-enter-active{transition: all 0.3s ease-out 0.6s;}

    .fadefour-enter-from{transform: translateY(400px);}
    .fadefour-enter-to{transform: translateY(0px);}
    .fadefour-enter-active{transition: all 0.3s ease-out 0.8s;}
  </style>
  